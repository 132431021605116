import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ArrowForward } from '@mui/icons-material'
import { IconButton, Paper, PaperProps } from '@mui/material'

import { CARD_PADDING } from '@constants'
import { useBreakpoints } from '@hooks'

export interface IAcCard extends PaperProps {
  cardColor?: 'white' | 'yellow' | 'green' | 'pink' | 'orange' | 'beige'
  cardBackgroundImage?: string
  contentColor?: string
  padding?: 'md' | 'lg'
  removePadding?: boolean
  fullWidth?: boolean
  maxWidth?: number
  center?: boolean
  sx?: object
  onClick?: () => void
  href?: string | undefined
  hideClickArrow?: boolean
}

export const AcCard = ({
  padding = 'md',
  cardColor = 'white',
  cardBackgroundImage,
  contentColor,
  removePadding = false,
  maxWidth,
  fullWidth,
  center,
  children,
  hideClickArrow = false,
  sx,
  href,
  onClick,
  ...props
}: IAcCard) => {
  const { isMobile, isDesktop, isTablet } = useBreakpoints()

  const spacing = useMemo(() => {
    if (isMobile) return CARD_PADDING.MOBILE
    if (isTablet) return CARD_PADDING.TABLET
    return CARD_PADDING.DESKTOP
  }, [isMobile, isDesktop, isTablet])

  const renderComponent = useMemo(() => {
    if (href) return Link
    if (onClick) return 'button'
    return 'div'
  }, [onClick, href])

  return (
    <Paper
      variant="outlined"
      component={renderComponent}
      to={href}
      onClick={onClick}
      tabIndex={renderComponent === 'div' ? -1 : 0}
      {...props}
      sx={theme => ({
        ...sx,
        cursor: renderComponent === 'div' ? 'initial' : 'pointer',
        position: 'relative',
        padding: removePadding ? 0 : theme.spacing(spacing[padding]),
        backgroundColor: theme.palette[cardColor].main,
        color: contentColor || theme.palette[cardColor].contrastText,
        border: cardColor === 'white' ? `1px solid #C1D6CC` : 'none',
        marginInline: center ? 'auto' : '',
        width: fullWidth ? '100%' : 'auto',
        maxWidth,
        backgroundImage: cardBackgroundImage && `url('${cardBackgroundImage}')`,
        backgroundSize: 'cover',
        '.MuiIconButton-root': {
          transition: 'transform 0.2s ease',
        },
        '&[data-name="animate-arrow"]': {
          '.MuiIconButton-root': {
            transform: 'translateX(4px)',
          },
        },
      })}>
      {children}
      {(href || onClick) && !hideClickArrow && (
        <IconButton
          data-name="animate-arrow"
          sx={theme => ({
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            color: theme.palette[cardColor].contrastText,
          })}>
          <ArrowForward />
        </IconButton>
      )}
    </Paper>
  )
}
